<template>
  <div>
    <app-main>
      <template v-slot:main-content>
        <div class="outer">
          <div class="menu">
            <el-menu :default-active="activeIndex" class="el-menu" mode="horizontal" @select="handleMenuOpen">
              <el-menu-item index="0">全部</el-menu-item>
              <el-menu-item index="1">淘宝</el-menu-item>
              <el-menu-item index="2">阿里巴巴</el-menu-item>
              <el-menu-item index="3">拼多多</el-menu-item>
              <el-menu-item index="4">抖音</el-menu-item>
            </el-menu>
            <div class="menu-right">
              <el-button size="small" @click="refresh">点击刷新</el-button>
              <el-button @click="addStore" size="small" type="primary">添加店铺</el-button>
            </div>
          </div>
          <div class="table-outer">
            <el-table :header-cell-style="{background:'#fafafa', color: 'rgba(0,0,0,.85)', fontWeight: '500'}" :data="tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)">
              <el-table-column label="序号">
                <template slot-scope="scope"><span>{{scope.$index+(currentPage - 1) * pageSize + 1}}</span></template>
              </el-table-column>
              <el-table-column label="店铺名称" prop="shopName"></el-table-column>
              <el-table-column label="到期截止时间" >
                <template slot-scope="scope"><span>{{timeConversion(scope.row.expireTime)}}</span></template>
              </el-table-column>
              <el-table-column label="自动发货">
              <template slot-scope="scope"><el-switch v-model="scope.row.send_switch" active-color="#13ce66" inactive-color="#ff4949" active-value="1" inactive-value="0" @change="handleSwitchChangeDebounce(scope.row)"></el-switch></template>
              </el-table-column>
              <el-table-column label="操作" >
                <template slot-scope="scope">
                  <el-button size="mini" type="primary" @click="Orderran(scope.row)" v-if="datetime>(scope.row.expireTime)">重新绑定</el-button>
                  <el-button size="mini"
                    @click="OrderDelete(scope.row)">删除绑定</el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="pagination">
              <el-pagination
                background
                @current-change="currentChange"
                :page-size="pageSize"
                layout="total, prev, pager, next, jumper"
                :current-page="currentPage"
                :total="tableData.length">
              </el-pagination>
            </div>
          </div>
          <el-dialog
            title="添加店铺"
            :visible.sync="dialogVisible"
            width="30%"
            >
            <div class="content">
              <span>选择店铺类型：</span>
              <div class="stores">
                <div @click="storesItemClick(index)" class="stores-item" :class="{'active': storesIndex === index}" v-for="(item, index) in stores" :key="index">
                  <span>{{item}}</span>
                </div>
              </div>
            </div>
            <div class="ding">
              <el-button type="primary" @click="getding">订购服务</el-button>
              <div class="red">备注：请先订购服务在进行授权绑定!</div>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="handleBindConfirm">确 定</el-button>
            </span>
          </el-dialog>
          <el-dialog
            :visible.sync="successDialogVisible"
            width="20%"
            center
            >
            <span class="content">
              是否授权成功？
            </span>
            <span slot="footer" class="dialog-footer">
              <el-button @click="successDialogVisible = false">授权失败</el-button>
              <el-button type="primary" @click="handleSuccessBind">我已成功授权</el-button>
            </span>
          </el-dialog>
        </div>
      </template>
    </app-main>
  </div>
</template>

<script>
import AppMain from 'components/baseStructure/AppMain'
import { storeBind, getBandStore, autoChange, getBandDelete } from '../../../network/api'
import { timeConversion } from 'utils/utils'
// import { shouquan } from 'network/api'

export default {
  components: {
    AppMain
  },
  computed: {
    // 使用时间戳转化函数
    timeConversion () {
      return timeConversion
    }
  },
  data () {
    return {
      activeIndex: '0',
      dialogVisible: false,
      successDialogVisible: false,
      currentPage: 1, // 当前页
      pageSize: 10,
      stores: ['淘宝', '拼多多', '抖音'],
      storesIndex: 0,
      // 0 淘宝 1 拼多多 2 抖音
      storeBindLink: [],
      tableData: [],
      allStoreList: [],
      pinStoreList: [],
      taoStoreList: [],
      douYinStoreList: [],
      timer: null, // vue中防抖函数 坑！
      firstEntery: true, // 标记是否是第一次进入页面
      datetime: '',
      dingurl: [],
      ding: 'https://fuwu.taobao.com/ser/detail.htm?service_code=ts-24066'
    }
  },
  created () {
    this.datetime = Date.parse(new Date())
    storeBind({ appkey: this.$appkey }).then(res => {
      console.log(res)
      this.storeBindLink[0] = res.data.tb_url
      this.storeBindLink[1] = res.data.pdd_url
      this.dingurl[0] = res.data.tb_service
      this.dingurl[1] = res.data.pdd_service
    })
    this.refreshStoreList()
  },
  methods: {
    addStore () {
      // this.dialogVisible = true
      this.dialogVisible = true
    },
    storesItemClick (index) {
      this.storesIndex = index
      if (index === 0) {
        this.ding = ''
        this.ding = this.dingurl[0]
      } else if (index === 1) {
        this.ding = ''
        this.ding = this.dingurl[1]
      } else if (index === 2) {
      }
    },
    getding () {
      window.open(this.ding, '_blank')
    },
    currentChange (val) {
      this.currentPage = val
    },
    Orderran (row) {
      if (row.band_type === '0') {
        window.open(this.storeBindLink[0], '_blank')
      } else if (row.band_type === '3') {
        window.open(this.storeBindLink[1], '_blank')
      }
    },
    OrderDelete (row) {
      getBandDelete({ storeId: row.id, appkey: this.$appkey }).then(res => {
        this.refreshStoreList()
        this.$message({
          message: res.msg,
          type: 'success'
        })
      })
    },
    handleBindConfirm () {
      this.dialogVisible = false
      this.successDialogVisible = true
      if (this.storesIndex === 0) {
        window.open(this.storeBindLink[0], '_blank')
      } else if (this.storesIndex === 1) {
        window.open(this.storeBindLink[1], '_blank')
      }
    },
    handleSuccessBind () {
      // close the dialog
      this.successDialogVisible = false
      // refresh store list
      this.refreshStoreList()
    },
    handleSwitchChangeDebounce (row) {
      this.debounce(this.handleSwitchChange, 1000)(row)
    },
    handleSwitchChange (row) {
      // console.log(row)
      autoChange({
        id: row.id,
        status: row.send_switch,
        appkey: this.$appkey
      }).then(res => {
        let type
        res.code === 1 ? type = 'success' : type = 'error'
        this.$message({
          message: res.msg,
          type
        })
      })
    },
    // 防抖函数
    debounce (fn, delay) {
      const that = this
      return function () {
        const args = arguments
        if (that.timer) clearTimeout(that.timer)
        that.timer = setTimeout(() => { fn.apply(that, args) }, delay)
      }
    },
    refreshStoreList () {
      this.allStoreList = []
      this.taoStoreList = []
      this.pinStoreList = []
      this.douYinStoreList = []
      getBandStore({ appkey: this.$appkey }).then(res => {
        console.log(res)
        // res.data got the store information
        for (let i = 0; i < res.data.length; i++) {
          // eslint-disable-next-line eqeqeq
          if (Number(res.data[i].band_type) === 0) {
            this.taoStoreList.push(res.data[i])
          } else if (Number(res.data[i].band_type) === 3) {
            this.pinStoreList.push(res.data[i])
          }
        }
        this.allStoreList = res.data
        this.tableData = res.data
        if (!this.firstEntery) {
          this.$message({
            message: '刷新成功',
            type: 'success'
          })
        }
        // first entery the page,do not show the refresh success message.
        this.firstEntery = false
      })
    },
    refresh () {
      this.debounce(this.refreshStoreList, 1000)()
    },
    handleMenuOpen (index) {
      // index is string type
      switch (+index) {
        case 0:
          this.tableData = this.allStoreList
          break
        case 1:
          this.tableData = this.taoStoreList
          break
        case 2:
          // alibaba data
          this.tableData = []
          break
        case 3:
          this.tableData = this.pinStoreList
          break
        case 4:
          this.tableData = []
          break
      }
    },
    // 时间戳转化
    timestampToTime (timestamp) {
      var newtime = timestamp.slice(0, 10)
      return Date.parse(newtime)
    }
  }
}
</script>

<style scoped src="styles/views/orderAdmin/storeBind.css">
</style>

<style>
.outer .el-dialog__header {
  border-bottom: solid 1px #e8e8e8;
}

.outer .el-dialog__footer {
  border-top: solid 1px #e8e8e8;
}
</style>
